<template>
	<div class="widht1200" style="color: #FFF;padding:0 40px;box-sizing: border-box;margin-top: 104px;">
		<div class="informatOne" v-for="(item,index) in news" :key="index">
			<div>
				<div style="font-size: 100px;color: #504760;line-height:90px;font-weight: bold;">
					{{computent(item.time)}}
					<div class="dateIcon">

					</div>
				</div>
				<div style="font-size: 60px;color: #504760;">
					Sep
				</div>
			</div>
			<div style="margin-left: 96px;padding-bottom: 100px;">
				<div style="margin-bottom: 30px;">
					<div class="OneTitle">
						{{item.title}}
					</div>
					<div class="OneTime">
						{{item.time}}
					</div>
				</div>
				<div class="OneDec">
					{{item.dec}}
				</div>
				<div class="OneBtn" @click="read(item)">
					阅读全文
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'information',
		data() {
			return {
				page:0,
				last:false,
				news: []
			}
		},
		mounted() {
			console.log(this)
			this.init(true);
		},
		destroyed() {
			window.removeEventListener("scroll", this.scroll, true);
			console.log("取消2")
		},
		methods: {
			init(one) {
				var temp = {
					dictKey: "ARMY_NEWS",
					p: this.page,
					s: 5
				}
				this.$dio.get("bottom-banner/page", {
					params: temp
				}).then(r => {
					if (r.data.status == 200) {
						if(this.page == 0){
							this.news = r.data.data.content;
						}else{
							this.news = r.data.data.content.concat(this.news);
						}
						this.last = r.data.data.last;
						this.page += 1;
						if(one)window.addEventListener("scroll", this.scroll, true);
					}
					console.log(r)
				});
			},
			computent(e) {
				console.log(e)
				return e.split(" ")[0].split("-")[2]
			},
			read(e) {
				this.$router.push({
					name: 'Artuic',
					query: {
						id: e.id
					}
				});
			},
			scroll() {
				console.log(this.last)
				// 距离底部200px时加载一次
				let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <=
					200
				if (bottomOfWindow && this.last == false) {
					this.last = true;
					this.init();
				}
			}
		}
	}
</script>

<style>
	.informatOne {
		flex-direction: row;
		position: relative;
	}

	.informatOne::before {
		content: "";
		width: 1px;
		height: 100%;
		background-color: #392F4B;
		position: absolute;
		left: -25px;
		top: 0;
	}

	.dateIcon {
		position: absolute;
		left: -36px;
		top: 35px;
		width: 19px;
		height: 19px;
		background: #160B2C;
		border: 2px solid #898394;
		border-radius: 50%;
	}


	.OneTitle {
		font-size: 30px;
		color: #FFFFFF;
		margin-bottom: 10px;
	}

	.OneTime {
		font-size: 16px;
		color: #898394;
	}

	.OneDec {
		font-size: 18px;
		color: #898394;
		line-height: 31px;
		margin-bottom: 20px;
	}

	.OneBtn {
		margin-top: 20px;
		width: 180px;
		height: 44px;
		background: #3DBDB6;
		border-radius: 22px;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		line-height: 44px;
	}
</style>

import axios from 'axios';

var baseURL = 'https://www.adota.cn/wxpro/';

const dio = axios.create({
  baseURL: baseURL,
  timeout: 1000
});


export default dio;
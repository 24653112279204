<template>
    <div id="app" class="app">
		<div class="headerTop widht1200">
			<div style=""></div>
			<div class="TopRight">
				<a :href="item.href" v-for="(item,index) in headList" :key="index">
					<div @click="jump(index)" class="TopRightTxt" :class="{TopRightBo:index==active1}">
						{{item.txt}}
					</div>
				</a>
			</div>
		</div>
		<div class="headerHr">
				
		</div>
        <router-view />
		<div id="BCooperation" class="BCooperation">
			<div class="widht1200" style="flex-direction: row;justify-content: center;">
				<div class="BCooperationOne">
					<div>
						<img class="BCooperationOneQrcode" src="@/assets/kf.png" />
					</div>
					<div class="BCooperationOneTip">
						添加客服微信
					</div>
				</div>
				<div class="BCooperationOne">
					<div>
						<img class="BCooperationOneQrcode" src="@/assets/bc.png" />
					</div>
					<div class="BCooperationOneTip">
						商务合作微信
					</div>
				</div>
				<div class="BCooperationOne">
					<div>
						<img class="BCooperationOneQrcode" src="@/assets/gzh.jpg" />
					</div>
					<div class="BCooperationOneTip">
						扫码添加公众号
					</div>
				</div>
			</div>
		</div>
		<div style="flex-direction: row;background-color: #0A001F;width: 100%;height: 55px;justify-content: center;align-items: center;font-size: 16px;color: #FFFFFF;">
			Copyright 2019 深圳风响科技有限公司. All Rights Reserved.<a style="text-decoration:underline;" href="https://beian.miit.gov.cn">粤ICP备19022192号</a>
		</div>
    </div>
</template>
<script>
	export default {
		data(){
			return {
				active1:0,
				headList: [{href:"#",txt:"首页"},{href:"#product",txt:"产品介绍"}, {href:"#mass",txt:"关于我们"},{href:"#Information",txt:"热点资讯"},{href:"#BCooperation",txt:"联系我们"}]
			}
		},
		created: function() {
			console.log('App Launch')
			var system = {
				win: false,
				mac: false,
				xll: false,
				ipad:false
			};
			//检测平台
			var p = navigator.platform;
			system.win = p.indexOf("Win") == 0;
			system.mac = p.indexOf("Mac") == 0;
			system.x11 = (p == "X11") || (p.indexOf("Linux") == 0);
			system.ipad = (navigator.userAgent.match(/iPad/i) != null)?true:false;
			//跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面
			if (system.win || system.mac || system.xll||system.ipad) {
				console.log("电脑端")
			} else {
				window.location.href = "https://www.doujuntuan.com/phone/";
			}
		},
		methods:{
			jump(e){
				console.log(this.$router)
				if(this.$router.history.current.name != "Home"){
					this.$router.replace("/")
				}
				this.active1 = e;
			}
		}
	}
</script>
<style>
    html,
    body {
        padding: 0;
        margin: 0;
		scroll-behavior:smooth;
    }
	a{
		text-decoration: none;
		color: inherit;
	}
	
	#app{
		
	}
	.app{
		min-width: 1200px;
		width: 100%;
		min-height: 100vh;
		align-items: center;
		background-color: #0F0426;
	}
	
	.widht1200{
		width: 1200px;
		align-items: center;
	}
	
	.headerTop {
		height: 66px;
		flex-direction: row;
		justify-content: flex-end;
		background-color: #0F0426;
	}
	
	.headerHr {
		width: 100%;
		height: 1px;
		background: #FFFFFF;
		opacity: 0.1;
	}
	
	@media screen and ( max-width: 1462px ){
		.widht1200{
			width: 1200px;
			align-items: center;
			overflow:hidden ;
		}
	}
	
	.BCooperation{
		width: 100%;
		height: 285px;
		background: #0A001F;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	
	.BCooperationOne{
		margin: 0 72.5px;
		align-items: center;
	}
	.BCooperationOneQrcode{
		width: 160px;
		height: 160px;
	}
	.BCooperationOneTip{
		font-size: 24px;
		color: #FFFFFF;
		margin-top: 17px;
	}
	.BCooperationOne{
		
	}
	.BCooperationOneQrcode{
		
	}
	.BCooperationOneTip{
		
	}
	
	
	.information{
		width: 100%;
		height: 654px;
		background-color: #160B2C;
		align-items: center;
	}
	.informationHead{
		margin-top: 88px;
	}
	.informationHeadImg{
		width: 737.53px;
		height: 100px;
	}
	
	
	.moreInformat{
		margin-top: 92px;
		width: 180px;
		height: 44px;
		background: #160B2C;
		border: 1px solid #3DBDB6;
		border-radius: 22px;
		line-height: 44px;
		text-align: center;
		font-size: 18px;
		color: #3DBDB6;
	}
</style>
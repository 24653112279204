<template>
	<div class="widht1200" style="color: #FFF;padding:0 40px;box-sizing: border-box;margin-top: 104px;">
		<div class="informatOne">
			<div>
				<div style="font-size: 100px;color: #504760;line-height:90px;font-weight: bold;">
					{{computent(content.time)}}
					<div class="dateIcon">
						
					</div>
				</div>
				<div style="font-size: 60px;color: #504760;">
					Sep
				</div>
			</div>
			<div style="margin-left: 96px;padding-bottom: 100px;">
				<div style="margin-bottom: 30px;">
					<div class="OneTitle">
						{{content.title}}
					</div>
					<div class="OneTime">
						{{content.time}}
					</div>
				</div>
				<div v-for="(item,index) in content.content" :key="index">
					<div class="OneDec" v-if="item.type== 'txt'">
						{{item.content}}
					</div>
					<div style="margin-bottom: 20px;" v-if="item.type == 'img'">
						<img style="height: auto;" :src="item.content" />
					</div>
				</div>
				
				
				<div class="OneBtn" @click="back">
					返回列表
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				id:"",
				content:{
					"title":"-",
					"time":"-",
					"content":[]
				}
			}
		},
		created() {
			this.id = this.$route.query.id;
		},
		mounted() {
			this.init();
		},
		methods:{
			computent(e){
				console.log(e)
				return e.split(" ")[0].split("-")[2]
			},
			init(){
				var temp = {
					key: '_id',
					value: this.id
				}
				this.$dio.get("dicts/string/", {
					params: temp
				}).then(r => {
					if(r.data.status == 200){
						this.content = r.data.data.value;
						
					}else{
						this.$message.error(r.data.data.message || "没有此文章")
					}
				});
			},
			back(){
				this.$router.back();
			}
		}
	}
</script>

<style scoped>
	
</style>

<template>
	<div class="home">
		<div class="header">
			<!-- <div class="headerTop widht1200">
				<div style=""></div>
				<div class="TopRight">
					<a :href="item.href" v-for="(item,index) in headList" :key="index">
						<div @click="jump(index)" class="TopRightTxt" :class="{TopRightBo:index==active1}">
							{{item.txt}}
						</div>
					</a>
				</div>
			</div>
			<div class="headerHr">
		
			</div> -->
			<div class="headerContent widht1200">
				<div class="headerCTitle">
					<div class="headerCIBox">
						<img class="headerCI" src="../assets/主题文字@2x.png" />
					</div>
					<div class="headerCTxt" style="letter-spacing: 1px;font-weight: 100;">
						专注于抖音短视频流量变现，拥有海量精美有趣的小程序，
					</div>
					<div class="headerCTxt" style="letter-spacing: 1px;font-weight: 100;">
						发视频挂上小程序就能轻松赚钱
					</div>
					<div class="headerCBBox">
						<div @click="jumpDownApp" class="headerCBtn" style="margin-right: 57px;cursor: pointer;">
							<div class="headerCBtnB">
								<img class="headerCBtnIcon" src="../assets/安卓@2x.png" />
							</div>
							<div class="headerCBtnBTxt">
								安卓端APP下载
							</div>
						</div>
						
						<el-popover style="cursor: pointer;" width="200" placement="bottom" title="扫码关注进入" trigger="manual" v-model="visible">
							
							<img style="width: 200px;height: 200px;" src="../assets/gzh.jpg" />
							<div slot="reference" class="headerCBtn" @click="visible = !visible">
								<div class="headerCBtnB">
									<img class="headerCBtnIcon" src="../assets/ios@2x.png" />
								</div>
								<div class="headerCBtnBTxt">
									IOS端公众号进入平台
								</div>
							</div>
						</el-popover>
						
					</div>
				</div>
				<div class="headerCR">
					<img class="headerCCover" src="../assets/插画@2x.png" />
				</div>
			</div>
		</div>
		
		<!--  -->
		<div id="home" class="dec">
			<div class="widht1200">
				<div class="decHead" style="margin-bottom: 57px;">
					<div style="margin-top: 40px;">
						<img class="decImg" src="../assets/zu796@2x.png" />
					</div>
					<div class="decIBox2">
						<img class="decImgTxt" src="../assets/822@2x.png" />
					</div>
					<div style="margin-top: 40px;">
						<img class="decImg" src="../assets/zu796@2x.png" />
					</div>
				</div>
				<div class="decCard">
					<div class="decCardOne">
						<div class="decCardOneIBox">
							<img class="decCardOneIcon1" src="../assets/782@2x.png" />
						</div>
						<div class="decCardOneTitle">
							无门槛
						</div>
						<div class="decCardOneTip">
							达人不需要橱窗，样品，无粉丝量要求，更不需要实名，只要你会用抖音发视频，就具备了推广赚钱的基本条件。
						</div>
					</div>
					<div class="decCardOne">
						<div class="decCardOneIBox">
							<img class="decCardOneIcon2" src="../assets/783@2x.png" />
						</div>
						<div class="decCardOneTitle">
							高收益
						</div>
						<div class="decCardOneTip">
							高转化率的产品，加上90%的超高分佣，万次播放收益可轻松达到100+，你的爆款视频里怎能没有小程序！
						</div>
					</div>
					<div class="decCardOne">
						<div class="decCardOneIBox">
							<img class="decCardOneIcon3" src="../assets/784@2x.png" />
						</div>
						<div class="decCardOneTitle">
							易涨粉
						</div>
						<div class="decCardOneTip">
							大主播适当引导使用小程序完全不伤粉丝，精美又好玩的小程序已经让许多主播的粉丝量大涨甚至翻倍。
						</div>
					</div>
				</div>
			</div>
		</div>
			
		<!--  -->
		<div class="product" id="product">
			<div class="widht1200" style="align-items: center;">
				<div class="decHead" style="margin-bottom: 15px;">
					<div class="decIBox1">
						<img class="decImg" src="../assets/798@2x.png" />
					</div>
					<div class="decIBox2">
						<img class="decImgTxt" src="../assets/819@2x.png" />
					</div>
					<div class="decIBox3">
						<img class="decImg" src="../assets/798@2x.png" />
					</div>
				</div>
				<div style="font-size: 28px;color: #FFFFFF;justify-content: center;align-items: center;">
					自研小程序累计用户已超过3亿，玩法多样，内容丰富，还可以为大流量达人定制专属小程序。
				</div>
				<div class="productPo">
					<div style="flex-basis: 33%;justify-content: center;align-items: center;margin-bottom: 44px;" v-for="(j,k) in productList" :key="k">
						<div class="productPoOne">
							<div class="productPoOneIcon">
								<img style="width: 100%;height: 100%;border-radius: 50%;" :src="j.src" />
							</div>
							<div class="productPoOneTxt">
								<div style="margin-top: 6px;">
									{{j.txt}}
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
			
			
		<!--  -->
		<div id="mass" class="mass">
			<div class="widht1200">
				<div class="massHead">
					<img class="massHeadImg" src="../assets/820@2x.png" />
				</div>
				<div class="massContent">
					<div class="massContentOne bg1">
						<div class="massContentOneTxt1">
							日活
						</div>
						<div class="massContentOneTxt2">
							1000w+
						</div>
					</div>
					<div class="massContentOne bg1">
						<div class="massContentOneTxt1">
							达人
						</div>
						<div class="massContentOneTxt2">
							100w+
						</div>
					</div>
					<div class="massContentOne bg1">
						<div class="massContentOneTxt1">
							用户
						</div>
						<div class="massContentOneTxt2">
							3亿+
						</div>
					</div>
					<div class="massContentOne bg1">
						<div class="massContentOneTxt1">
							产品
						</div>
						<div class="massContentOneTxt2">
							1000款+
						</div>
					</div>
				</div>
				<div id="about" class="about">
					<img class="aboutImg" src="../assets/821@2x.png" />
				</div>
				<div class="team">
					<div class="animated" style="margin-right: 164px;" :class="{fadeInLeft:am}">
						<img class="teamImg" src="../assets/group_presentation@2x.png" />
					</div>
					<div class="teamLeft animated" :class="{fadeInRight:am}">
						<div class="teamBoxOne" style="margin-bottom: 71px;">
							<div class="teamBoxOneD">
								<div class="teamBoxOneDTil">
									实力团队
								</div>
								<div class="teamBoxOneImgBox">
									<img class="teamBoxOneIcon" src="../assets/802@2x.png" />
								</div>
							</div>
							<div class="teamBoxOneT">
								公司地处深圳前海自贸区，超甲级写字楼办公环境，拥有超过十年互联网产品开发经验的实力团队。资历老但年龄不老，成员均未及而立之年，要么是来自互联网巨头的大咖，要么是名校刚毕业的活力新人，灵感与创意每一天都迸发溢出。源源不绝的好点子加上过硬的技术实力成就了现在的抖军团，让产品好到任何人都无法拒绝是我们的唯一宗旨。
							</div>
						</div>
						<div class="teamBoxOne">
							<div class="teamBoxOneD">
								<div class="teamBoxOneDTil">
									发展愿景
								</div>
								<div class="teamBoxOneImgBox">
									<img class="teamBoxOneIcon" src="../assets/802@2x.png" />
								</div>
							</div>
							<div class="teamBoxOneT">
								开发出覆盖抖音所有类目下用户的小程序，做最稳定最高佣的短视频变现平台。
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	
		<div id="Information" class="information">
			<div class="widht1200">
				<div class="informationHead">
					<img class="informationHeadImg" src="../assets/zu336.png" />
				</div>
				<div style="width: 100%;flex-direction: row;justify-content: space-between;margin-top: 120px;">
					<div id="arturc" style="width: 552px;" v-for="(item,index) in homeNews" :key="index" @click="jumpAtt(item)">
						<div>
							<div style="color: #FFFFFF;font-size: 24px;flex-direction: row;align-items: center;">
								<div>
									{{item.title}}
								</div>
								<div style="margin-left:21px;margin-top: 6px;">
									<img style="width:20px;height: 20px;" src="../assets/lj779@2x.png" />
								</div>
							</div>
							<div style="font-size: 16px;color: #898394;">
								{{item.time}}
							</div>
						</div>
						<div style="font-size: 18px;color: #898394;margin-top: 28px;">
							{{item.dec}}
						</div>
					</div>
				</div>
				<div class="moreInformat" @click="navgat">
					更多资讯>
				</div>
			</div>
		</div>
		
		
			
		<!--  -->
		<!-- <div id="BCooperation" class="BCooperation">
			<div class="widht1200" style="flex-direction: row;justify-content: center;">
				<div class="BCooperationOne">
					<div>
						<img class="BCooperationOneQrcode" src="../assets/kf.png" />
					</div>
					<div class="BCooperationOneTip">
						添加客服微信
					</div>
				</div>
				<div class="BCooperationOne">
					<div>
						<img class="BCooperationOneQrcode" src="../assets/bc.png" />
					</div>
					<div class="BCooperationOneTip">
						商务合作微信
					</div>
				</div>
				<div class="BCooperationOne">
					<div>
						<img class="BCooperationOneQrcode" src="../assets/gzh.jpg" />
					</div>
					<div class="BCooperationOneTip">
						扫码添加公众号
					</div>
				</div>
			</div>
		</div>
		<div style="flex-direction: row;background-color: #0A001F;width: 100%;height: 55px;justify-content: center;align-items: center;font-size: 16px;color: #FFFFFF;">
			Copyright 2019 深圳风响科技有限公司. All Rights Reserved.<a style="text-decoration:underline;" href="https://beian.miit.gov.cn">粤ICP备19022192号</a>
		</div> -->
	</div>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'Home',
		data() {
			return {
				homeNews:[],
				dialogTableVisible:true,
				visible:false,
				offsetTopL:{
					product:0,
					mass:0,
					BCooperation:0
				},
				ap:false,
				am:false,
				ab:false,
				active1:0,
				productList:[
					{src:require("@/assets/AI人脸融合.png"),txt:"AI人脸融合"},
					{src:require("@/assets/答题冲关与挑战.png"),txt:"答题冲关与挑战"},
					{src:require("@/assets/角色大冒险.png"),txt:"角色大冒险"},
					{src:require("@/assets/精美游戏测试.png"),txt:"精美游戏测试"},
					{src:require("@/assets/趣味剧情测试.png"),txt:"趣味剧情测试"},
					{src:require("@/assets/趣味小工具.png"),txt:"趣味小工具"},
					{src:require("@/assets/趣味姓名测试.png"),txt:"趣味姓名测试"},
					{src:require("@/assets/塔罗情感测试.png"),txt:"塔罗情感测试"},
					{src:require("@/assets/语音cp配对.png"),txt:"语音cp配对"}
				],
				headList: [{href:"#",txt:"首页"},{href:"#product",txt:"产品介绍"}, {href:"#mass",txt:"关于我们"},{href:"#BCooperation",txt:"联系我们"}]
			}
		},
		mounted() {
			this.init();
			window.addEventListener('scroll',this.handleScroll,true)
			this.offsetTopL.product = document.getElementById("home").offsetTop
			this.offsetTopL.mass = document.getElementById("product").offsetTop
			this.offsetTopL.BCooperation = document.getElementById("mass").offsetTop
		},
		deactivated(){
			console.log("取消")
		},
		beforeDestroy(){
			console.log("取消1")
		},
		destroyed(){
			window.removeEventListener("scroll",this.handleScroll,true)
			console.log("取消2")
		},
		methods:{
			init(){
				var temp = {
					key: '_id',
					value: "ARMY_HOME_NEWS"
				}
				this.$dio.get("dicts/string/", {
					params: temp
				}).then(r => {
					if(r.data.status == 200){
						this.homeNews = r.data.data.value;
					}else{
						this.$message.error(r.data.data.message || "没有此文章")
					}
				});
			},
			jumpAtt(e){
				this.$router.push({
					name: 'Artuic',
					query: {
						id: e.id
					}
				});
			},
			handleScroll(e){
				// console.log(e)
				var tempOffTop = e.target.documentElement.scrollTop || e.target.body.scrollTop;
				if(this.offsetTopL.product <= tempOffTop){
					// console.log("到达产品啦")
					this.ap = true;
				}
				if(this.offsetTopL.mass <= tempOffTop){
					this.am = true;
				}
				if(this.offsetTopL.BCooperation <= tempOffTop){
					// console.log("到达关于啦")
					this.ab = true;
				}
			},
			jumpDownApp(){
				window.location.href = "https://www.adota.cn/Army_DOWNLOAD/downApp.html";
			},
			navgat(){
				// <router-link :to="...">
				this.$router.push({ name:'information'})
			}
		}
	}
</script>

<style>
	@import url("../common/animate.css");
	div {
		display: flex;
		flex-direction: column;
	}

	.home {
		min-width: 1200px;
		width: 100%;
		align-items: center;
	}

	.header {
		width: 100%;
		height: 760px;
		background-color: #0F0426;
		background-image: url(~@/assets/Rectangle2@2x.png);
		background-size: 100% 100%;
		align-items: center;
	}

	.TopRight {
		flex-direction: row;
		/* flex-grow: 1; */
		/* justify-content: space-around; */
		/* margin-right: 340px; */
		/* flex-grow: 1; */
	}

	.TopRightTxt {
		padding: 27px 0px 23px;
		text-align: center;
		width: 72px;
		box-sizing: border-box;
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 14px;
		color: rgba(255, 255, 255, .5);
		margin-left: 38px;
	}

	.TopRightBo {
		border-bottom: 4px solid #3DBDB6;
		color: #FFFFFF;
	}


	.headerContent {
		height: 100%;
		flex-direction: row;
		align-items: center;
	}

	.headerCTitle {}

	.headerCIBox {
		margin-bottom: 18px;
	}

	.headerCI {
		width: 468px;
		height: 160px;
	}

	.headerCTxt {
		
		font-size: 18px;
		color: #FFFFFF;
	}

	.headerCBBox {
		flex-direction: row;
		font-size: 18px;
		color: #FFFFFF;
		margin-top: 40px;
	}

	.headerCBtn {
		flex-direction: row;
		width: 260px;
		height: 52px;
		background: #3DBDB6;
		box-shadow: 0px 0px 8px rgba(177, 177, 177, 0.5);
		border-radius: 26px;
		justify-content: center;
		align-items: center;
	}

	.headerCBtnB {
		margin-right: 10px;
	}

	.headerCBtnIcon {
		width: 20px;
		height: 24px;
	}

	.headerCBtnBTxt {}

	.headerCR {}

	.headerCCover {
		width: 743px;
		height: 486.5px;
	}
	.dec{
		width: 100%;
		height: 840px;
		background: #1F084C;
		align-items: center;
	}
	
	.decHead{
		margin-top: 22px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.decIBox1{
		margin-top: 34px;
	}
	.decIBox2{
		/* margin-bottom: 50px; */
	}
	.decIBox3{
		margin-top: 34px;
	}
	.decImg{
		width: 117px;
		height: 46px;
		margin: 0 31px;
	}
	.decImgTxt{
		width: 446px;
		height: 141px;
	}
	
	.decCard{
		flex-direction: row;
		justify-content: center;
	}
	.decCardOne{
		background-image: url(~@/assets/816@2x.png);
		width: 380px;
		height: 500px;
		justify-content: center;
		align-items: center;
		margin: 0 15px;
	}
	
	.decCardOneIBox{
		margin-bottom: 76px;
	}
	.decCardOneIcon1{
		width: 93.53px;
		height: 115.31px;
	}
	.decCardOneIcon2{
		width: 119.56px;
		height: 108.44px;
	}
	.decCardOneIcon3{
		width: 110.73px;
		height: 108.44px;
	}
	.decCardOneTitle{
		font-size: 18px;
		color: #FFFFFF;
		margin-bottom: 25px;
	}
	.decCardOneTip{
		width: 279px;
		height: 84px;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: rgba(255,255,255,.7);
	}
	
	
	
	.product{
		width: 100%;
		height: 1129px;
		background: #160B2C;
		align-items: center;
	}
	
	.productPo{
		flex-direction: row;
		width: 1012px;
		margin-top: 122px;
		flex-wrap: wrap;
	}
	.productPoOne{
		width: 250px;
		align-items: center;
	}
	.productPoOneIcon{
		width: 104px;
		height: 104px;
		background: #FFFFFF;
		border: 2px solid #FA6832;
		border-radius: 50%;
	}
	.productPoOneTxt{
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 58px;
		margin-top: 15px;
		background-image: url(../assets/1@2x.png);
		background-size: 100% 100%;	
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
	}
	
	
	
	.mass{
		width: 100%;
		height: 1375px;
		background-color: #1F084C;
		align-items: center;
	}
	.massHead{
		margin-top: 29px;
	}
	.massHeadImg{
		width: 950px;
		height: 165px;
	}
	.massContent{
		flex-direction: row;
	}
	.massContentOne{
		position: relative;
		justify-content: center;
		align-items: center;
		width: 236px;
		height: 236px;
		background-size: 100% 100%;
		margin: 0 24.5px;
	}
	.bg1{
		background-image: url(../assets/jq1@2x.png);
	}
	.bg2{
		background-image: url(../assets/jq2@2x.png);
	}
	.bg3{
		background-image: url(../assets/jq3@2x.png);
	}
	.bg4{
		background-image: url(../assets/jq4@2x.png);
	}
	.massContentOneTxt1{
		font-size: 36px;
		color: #FFFFFF;
	}
	.massContentOneTxt2{
		font-size: 24px;
		color: #FFFFFF;
	}
	.about{
		margin-top: 115px;
	}
	.aboutImg{
		width: 584px;
		height: 141px;
	}
	.team{
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 76px;
	}
	
	
	.teamImg{
		width: 480px;
		height: 401px;
	}
	.teamLeft{
		
	}
	.teamBoxOne{
		width: 564px;
	}
	.teamBoxOneD{
		flex-direction: row;
		align-items: center;
	}
	.teamBoxOneDTil{
		font-size: 24px;
		color: #FFFFFF;
		margin-right: 10px;
	}
	.teamBoxOneImgBox{
		
	}
	.teamBoxOneIcon{
		width: 42px;
		height: 19px;
	}
	.teamBoxOneT{
		font-size: 16px;
		color: #72808E;
		margin-top: 19px;
	}
	
	.BCooperation{
		width: 100%;
		height: 285px;
		background: #0A001F;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	
	.BCooperationOne{
		margin: 0 72.5px;
		align-items: center;
	}
	.BCooperationOneQrcode{
		width: 160px;
		height: 160px;
	}
	.BCooperationOneTip{
		font-size: 24px;
		color: #FFFFFF;
		margin-top: 17px;
	}
	.BCooperationOne{
		
	}
	.BCooperationOneQrcode{
		
	}
	.BCooperationOneTip{
		
	}
	
	
	.information{
		width: 100%;
		height: 654px;
		background-color: #160B2C;
		align-items: center;
	}
	.informationHead{
		margin-top: 88px;
	}
	.informationHeadImg{
		width: 737.53px;
		height: 100px;
	}
	
	
	.moreInformat{
		margin-top: 49px;
		width: 180px;
		height: 44px;
		background: #160B2C;
		border: 1px solid #3DBDB6;
		border-radius: 22px;
		line-height: 44px;
		text-align: center;
		font-size: 18px;
		color: #3DBDB6;
	}
	
	#arturc,.moreInformat:hover{
		cursor: pointer;
	}
</style>

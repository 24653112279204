import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue';
import Information from '@/views/Information/Information.vue';
import Artuic from '@/views/Artuic/Artuic.vue';

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: '抖军团'
		}
	},
	{
		path: '/information', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
		name: 'information',
		component: Information
	},
	{
		path: '/Artuic', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
		name: 'Artuic',
		component: Artuic
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
